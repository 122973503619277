export const secondsToHMS = (seconds: number) => {
    var hours = Math.floor(seconds / 3600);
    var minutes = Math.floor((seconds % 3600) / 60);
    var remainingSeconds = seconds % 60;
    var res = ''
    if (hours) res = res + `${hours}:`;
    res = res + minutes + ":";
    res = res + remainingSeconds;
    return res;
}

export const numberFormat = (num: number) => {
    if (num < 1000) return num;
    else return `${(Math.round(num / 100) / 10)}K`
}

export const pastTimeFormat = (postedAt: string) => {
    var localPostedAt = new Date(postedAt).toLocaleDateString();
    let postDate = new Date(localPostedAt);
    let currentDate = new Date();
    var seconds = Math.round(Math.abs(currentDate.getTime() - postDate.getTime()) / 1000);
    if (seconds < 60) return `${seconds} seconds`;
    else if (seconds < 3600) return `${Math.floor(seconds / 60)} minute${Math.floor(seconds / 60) > 1 ? 's' : ''}`
    else if (seconds < 3600 * 24) return `${Math.floor(seconds / (3600))} hour${Math.floor(seconds / (3600)) > 1 ? 's' : ''}`
    else if (seconds < 3600 * 24 * 30) return `${Math.floor(seconds / (3600 * 24))} day${Math.floor(seconds / (3600 * 24)) > 1 ? 's' : ''}`
    else if (seconds < 3600 * 24 * 30 * 12) return `${Math.floor(seconds / (3600 * 24 * 30))} month${Math.floor(seconds / (3600 * 24 * 30)) > 1 ? 's' : ''}`
    else return `${Math.floor(seconds / (3600 * 24 * 30 * 12))} year${Math.floor(seconds / (3600 * 24 * 30 * 12)) > 1 ? 's' : ''
        }`
}