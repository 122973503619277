import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface videoState {
    uploadDlg: boolean
}

const initialState: videoState = {
    uploadDlg: false
}   

export const videoSlice = createSlice({
    name: "video",
    initialState,
    reducers: {
        setUploadModalShow: (state, action: PayloadAction<boolean>) => {
            state.uploadDlg = action.payload;
        },
    }
})

export const { setUploadModalShow } = videoSlice.actions

export default videoSlice.reducer;