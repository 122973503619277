import React from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Flex, Row, Col, Avatar, Typography, Button, Image, Input, Divider } from "antd";
import { SearchOutlined, EditOutlined } from '@ant-design/icons';
import { useSelector } from "react-redux";
import { RootState } from "store";
import VideoCard from "components/common/VideoCard";
import EditProfileDialog from "components/dialog/EditProfileDialog";
import { store } from "store";
import { setMessage } from "store/slices/commonSlice";
import { setUser } from "store/slices/userSlice";
import { apis } from "api";
import { numberFormat } from "libs/common";
import { useWindowSize } from "libs/hook";
import DefaultAvatar from "assets/images/default-avatar.jpeg";

import CoverImage from "assets/images/cover.png";
import Image1 from "assets/images/images1.png";
import Image2 from "assets/images/images2.png";
import Image3 from "assets/images/images3.png";

const Profile: React.FC = () => {
    const { username } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { profileUser } = useSelector((state: RootState) => state.user);
    const { currentUser } = useSelector((state: RootState) => state.common);
    const [width] = useWindowSize();
    const [openEditDialog, setOpenEditDialog] = React.useState(false);

    const getUserByUsername = async (username: string) => {
        try {
            const response: any = await apis.GetProfileUserByUsername(username);
            store.dispatch(setUser(response.user))
        } catch (err: any) {
            store.dispatch(setMessage({
                type: 'error',
                content: err?.response?.data.error || 'Network Error'
            }));
        }
    }

    const isSelfProfile = React.useMemo(() => {
        return profileUser && currentUser && profileUser.id === currentUser.id;
    }, [profileUser, currentUser]);

    React.useEffect(() => {
        if (username) {
            getUserByUsername(username);
        }
    }, [username, location]);

    return (
        <Row style={{ maxWidth: 1200, marginLeft: 'auto', marginRight: 'auto' }}>
            <Row style={{
                background: 'white',
                borderRadius: 10,
                paddingBottom: 10,
                boxShadow: '0px 2px 2px rgba(0, 0, 0, .02), 0px 0px 2px rgba(0, 0, 0, .5), 0px 1px 4px rgba(0, 0, 0, .08)'
            }}>
                <Col span={24}>
                    <Image
                        width={'100%'}
                        height={width > 650 ? 150 : 100}
                        preview={false}
                        src={CoverImage}
                        style={{ objectFit: 'cover', borderRadius: '10px 10px  0px 0px' }}
                        alt="Cover Image"
                    />
                    <Avatar
                        style={{
                            marginLeft: width > 650 ? 30 : 20,
                            marginTop: width > 650 ? -80 : -60,
                            boxShadow: '0px 2px 2px rgba(0, 0, 0, .02), 0px 0px 2px rgba(0, 0, 0, .5), 0px 1px 4px rgba(0, 0, 0, .08)'
                        }}
                        size={{ xs: 100, sm: 120, md: 130, lg: 130, xl: 130, xxl: 130 }}
                        src={(profileUser && profileUser.avatar) ? profileUser.avatar : DefaultAvatar}
                    />
                </Col>
                <Row style={{ margin: `10px ${width > 650 ? 30 : 20}px`, position: 'relative', width: '100%' }}>
                    {isSelfProfile ?
                        <Button
                            style={{ position: 'absolute', top: -30, right: 0 }}
                            icon={<EditOutlined />}
                            size="large"
                            type="text"
                            onClick={() => setOpenEditDialog(true)}
                        />
                        : null}
                    <Col span={24}>
                        <Flex gap={10} align="flex-end">
                            <Typography.Title level={3} style={{ margin: 0, fontWeight: 'bold' }}>
                                {profileUser?.name}
                            </Typography.Title>
                            <Typography.Title level={5} style={{ margin: 0, color: 'grey' }}>
                                {profileUser?.username}
                            </Typography.Title>
                        </Flex>
                    </Col>
                    <Col span={24} style={{ margin: '5px 0px' }}>
                        <Flex gap={10}>
                            <Flex align="center" gap={5}>
                                <Typography.Text style={{ margin: 0, fontWeight: 'bold', color: 'grey' }}>
                                    {profileUser ? numberFormat(profileUser.subscribers) : ''}
                                </Typography.Text>
                                <Typography.Text style={{ margin: 0, color: 'grey' }}>
                                    Subscribers
                                </Typography.Text>
                            </Flex>
                            <Flex align="center" gap={5}>
                                <Typography.Text style={{ margin: 0, fontWeight: 'bold', color: 'grey' }}>
                                    {profileUser ? numberFormat(profileUser.videos) : ''}
                                </Typography.Text>
                                <Typography.Text style={{ margin: 0, color: 'grey' }}>
                                    Videos
                                </Typography.Text>
                            </Flex>
                            <Flex align="center" gap={5}>
                                <Typography.Text style={{ margin: 0, fontWeight: 'bold', color: 'grey' }}>
                                    {profileUser ? numberFormat(profileUser.views) : ''}
                                </Typography.Text>
                                <Typography.Text style={{ margin: 0, color: 'grey' }}>
                                    Views
                                </Typography.Text>
                            </Flex>
                        </Flex>
                    </Col>
                    <Col span={24}>
                        <Typography.Paragraph style={{ marginBottom: 0, fontSize: 15 }}>
                            {profileUser?.bio}
                        </Typography.Paragraph>
                    </Col>
                    {!isSelfProfile ?
                        <Col span={24} style={{ marginTop: 10 }}>
                            <Button
                                shape="round"
                                type="primary"
                            >
                                Subscribe
                            </Button>
                        </Col> : null}
                </Row>
            </Row>
            <Col span={24}>
                <Flex justify="flex-end">
                    <Input
                        placeholder="Search..."
                        prefix={<SearchOutlined />}
                        style={{ width: 200, marginTop: 10 }}
                    />
                </Flex>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ margin: 0, fontWeight: 'bold' }}>
                    Household
                </Typography.Title>
                <Divider style={{ margin: 0, borderWidth: 3, marginBottom: 10 }} />
                <Row>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, i) => (
                        <Col lg={6} md={8} sm={12} xs={24} style={{ padding: '5px' }} key={i}>
                            <VideoCard
                                thumbnail={Image1}
                                duration={200}
                                title="My video"
                                avatar={profileUser?.avatar}
                                name={profileUser ? profileUser.name : ''}
                                views={4}
                                postedAt={'Tue Feb 25 2024 12:00:00 GMT+0000'}
                            />
                        </Col>
                    ))}
                </Row>
            </Col>
            <Col span={24} style={{ marginTop: 20 }}>
                <Typography.Title level={4} style={{ margin: 0, fontWeight: 'bold' }}>
                    Beauty
                </Typography.Title>
                <Divider style={{ margin: 0, borderWidth: 3, marginBottom: 10 }} />
                <Row>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, i) => (
                        <Col lg={6} md={8} sm={12} xs={24} style={{ padding: '5px' }} key={i}>
                            <VideoCard
                                thumbnail={Image2}
                                duration={200}
                                title="My beauty"
                                avatar={profileUser?.avatar}
                                name={profileUser ? profileUser.name : ''}
                                views={445}
                                postedAt={'Tue Feb 27 2024 01:00:00 GMT+0000'}
                            />
                        </Col>
                    ))}
                </Row>
            </Col>
            <Col span={24} style={{ marginTop: 20 }}>
                <Typography.Title level={4} style={{ margin: 0, fontWeight: 'bold' }}>
                    Pet
                </Typography.Title>
                <Divider style={{ margin: 0, borderWidth: 3, marginBottom: 10 }} />
                <Row>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, i) => (
                        <Col lg={6} md={8} sm={12} xs={24} style={{ padding: '5px' }} key={i}>
                            <VideoCard
                                thumbnail={Image3}
                                duration={200}
                                title="My pet"
                                avatar={profileUser?.avatar}
                                name={profileUser ? profileUser.name : ''}
                                views={45345}
                                postedAt={'Tue Feb 27 2023 12:00:00 GMT+0000'}
                            />
                        </Col>
                    ))}
                </Row>
            </Col>
            <Col span={24}>
                <Divider style={{ margin: 0, borderWidth: 3, marginTop: 10 }} />
            </Col>
            {(profileUser && isSelfProfile && openEditDialog) ?
                <EditProfileDialog
                    open={openEditDialog}
                    setOpen={setOpenEditDialog}
                    id={profileUser.id}
                    name={profileUser.name}
                    username={profileUser.username}
                    bio={profileUser.bio}
                    handleSuccess={(url: string) => navigate(`/${url}`)}
                /> : null}
        </Row>
    )
}

export default Profile;