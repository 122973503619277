export const ACCESS_TOKEN = 'kritikki_access_token';
export const CATEGORY_LIST = [
    'Beauty',
    'Skincare',
    'Haircare',
    'Cosmetics',
    'Bath and Body',
    'Personal Care',
    'Fitness',
    'Health',
    'Food and Drinks',
    'Clothing, shoes, and accessories',
    'Electronics',
    'Tech',
    'Tools',
    'Appliances',
    'Books, Movies and Music',
    'Auto',
    'Toys and games',
    'Baby and Kids',
    'Pets',
    'Household',
    'Kitchen',
    'Office, School, & Art Supplies',
    'Flowers and Plants',
    'Outdoors and Sports',
    'Travel'
]