import React from "react";
import { Flex, Button, Typography } from "antd";

const AppFooter: React.FC = () => {
    return (
        <Flex vertical justify="center" align="center">
            <Button type="link" href="/terms">Terms of Service</Button>
            <Button type="link" href="/privacy">Privacy and Policy</Button>
            <Typography.Text style={{ fontWeight: 500, marginTop: 5 }}>©{new Date().getFullYear()} KriTikki</Typography.Text>
        </Flex>
    )
}

export default AppFooter;