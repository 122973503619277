export const color = {
    pink: '#D12661',
    orange1: '#FF9761',
    sky: '#3DC6E9',
    orange2: '#FF6161'
}

export const theme = {
    components: {
        Button: {
            colorPrimary: color.sky,
            colorPrimaryHover: color.sky,
            colorPrimaryActive: color.sky,
            defaultBorderColor: color.sky,
            // defaultHoverBorderColor: color.orange1,
            defaultColor: color.sky,
            // defaultHoverColor: color.orange1
        },
        Input: {
            colorBorder: color.sky,
            colorPrimary: color.sky,
            colorPrimaryHover: color.sky,
        },
        Select: {
            colorBorder: color.sky,
            colorPrimaryHover: color.sky
        }
    },
}