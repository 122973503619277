import React from "react";
import axios from "axios";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import { GoogleOutlined } from '@ant-design/icons';
import { apis } from "api";
import { IGoogleAuthRequest } from "libs/types";
import { store } from "store";
import { login, setMessage } from "store/slices/commonSlice";

const GoogleLoginComponent: React.FC<{ isLogin: boolean }> = ({ isLogin }) => {
    const navigate = useNavigate();

    const googleLogin = useGoogleLogin({
        flow: "implicit",
        onSuccess: async (tokenResponse) => {
            try {
                const userInfo = await axios.get(
                    "https://www.googleapis.com/oauth2/v3/userinfo",
                    { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } }
                );
                const { data } = userInfo;
                const userData: IGoogleAuthRequest = {
                    uid: data.sub,
                    email: data.email,
                    name: `${data.given_name} ${data.family_name}`,
                    avatar: data.picture,
                };

                const response: any = await apis.GoogleAuth(userData);
                store.dispatch(login(response.token));
                store.dispatch(setMessage({
                    type: 'success',
                    content: 'Logged in successfully.'
                }))
                navigate('/');
            } catch (err) {
                console.warn(err);
            }
        },
        onError: (errorResponse) => console.warn(errorResponse),
    });

    return (
        <Button
            size="large"
            icon={<GoogleOutlined />}
            style={{ width: '100%' }}

            onClick={() => googleLogin()}
        >
            {isLogin ? 'Log in with Google' : 'Sign up with Google'}
        </Button>
    );
};

const CustomGoogleLogin: React.FC<{ isLogin: boolean }> = ({ isLogin }) => {
    const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

    return (
        <GoogleOAuthProvider clientId={googleClientId as string}>
            <GoogleLoginComponent isLogin={isLogin} />
        </GoogleOAuthProvider>
    );
};

export default CustomGoogleLogin;