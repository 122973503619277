import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import AppLayout from "layout";

// Pages
import Home from "pages/Home";
import Profile from "pages/Profile";
import Login from "pages/Auth/Login";
import Register from "pages/Auth/Register";
import VideoDetail from "pages/Video";
import Terms from "pages/Terms";
import Privacy from "pages/Privacy";

const AppRouter = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<AppLayout child={<Home />} />} />
                <Route path="/login" element={<AppLayout auth={true} child={<Login />} />} />
                <Route path="/signup" element={<AppLayout auth={true} child={<Register />} />} />
                <Route path="/videos/:videoId" element={<AppLayout child={<VideoDetail />} />} />
                <Route path="/terms" element={<AppLayout child={<Terms />} />} />
                <Route path="/privacy" element={<AppLayout child={<Privacy />} />} />
                <Route path="/:username" element={<AppLayout child={<Profile />} />} />
            </Routes>
        </Router>
    )
}

export default AppRouter