import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import { theme } from 'libs/theme';
import AppRouter from 'routes';
import { store } from 'store';

function App() {
  return (
    <Provider store={store}>
      <ConfigProvider theme={theme}>
        <AppRouter />
      </ConfigProvider>
    </Provider>
  );
}

export default App;
