import React from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Row, Col, Typography, Input, Form, Button, Divider } from "antd";
import { MailOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import { apis } from "api";
import { store } from "store";
import { login, setMessage } from "store/slices/commonSlice";
import CustomGoogleLogin from "components/auth/CustomGoogleLogin";
import Logo from "assets/images/logo.png";

type FieldType = {
    name?: string;
    email?: string;
    password?: string;
};

const Register: React.FC = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState<boolean>(false);

    const onFinish = async (values: any) => {
        try {
            setLoading(true);
            const response: any = await apis.Register(values);
            setLoading(false);
            store.dispatch(login(response.token));
            store.dispatch(setMessage({
                type: 'success',
                content: 'Logged in successfully.'
            }))
            navigate('/');
        } catch (err: any) {
            setLoading(false);
            store.dispatch(setMessage({
                type: 'error',
                content: err?.response?.data.error
            }))
        }
    };

    return (
        <Flex
            style={{
                width: '100%',
                height: 'calc(100vh - 60px)'
            }}
            vertical
            align="center"
            justify="center"
        >
            <Row
                style={{
                    margin: 20,
                    padding: '20px 30px',
                    background: 'white',
                    borderRadius: 20,
                    maxWidth: 400,
                    boxShadow: '0px 2px 2px rgba(0, 0, 0, .02), 0px 0px 2px rgba(0, 0, 0, .5), 0px 1px 4px rgba(0, 0, 0, .08)'
                }}
            >
                <Col span={24} style={{ marginTop: 10 }}>
                    <Flex justify="center">
                        <img
                            src={Logo}
                            style={{ width: 180, cursor: 'pointer' }}
                            onClick={() => navigate('/')}
                            alt="Logo"
                        />
                    </Flex>
                </Col>
                <Col span={24}>
                    <Typography.Title level={2} style={{ textAlign: 'center', margin: 5 }}>
                        Create an account
                    </Typography.Title>
                </Col>
                <Col span={24} style={{ marginTop: 20 }}>
                    <Form
                        name="basic"
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Form.Item<FieldType>
                            name="name"
                            rules={[{ required: true, message: 'Please input your name!' }]}
                        >
                            <Input
                                size="large"
                                prefix={<UserOutlined />}
                                placeholder="Name"
                            />
                        </Form.Item>

                        <Form.Item<FieldType>
                            name="email"
                            rules={[{ required: true, message: 'Please input your email!' }]}
                        >
                            <Input
                                size="large"
                                prefix={<MailOutlined />}
                                placeholder="Email"
                            />
                        </Form.Item>

                        <Form.Item<FieldType>
                            name="password"
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password
                                size="large"
                                prefix={<LockOutlined />}
                                placeholder="Password"
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                style={{ width: '100%' }}
                                loading={loading}
                            >
                                Sign up
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
                <Col span={24} style={{ marginTop: -20 }}>
                    <Divider plain>Or</Divider>
                </Col>
                <Col span={24}>
                    <CustomGoogleLogin isLogin={false} />
                </Col>
                <Col span={24} style={{ marginTop: 20, marginBottom: 20, textAlign: 'center' }}>
                    <Typography.Text>
                        Already have an account?<Button type="link" style={{ padding: 5 }} onClick={() => navigate('/login')}>Log in</Button>
                    </Typography.Text>
                </Col>
            </Row>
        </Flex>
    )
}

export default Register;