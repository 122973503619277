import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Layout, message as Message } from "antd";
import AppHeader from "./Header";
import AppSider from "./Sider";
import AppDrawer from "./Drawer";
import type { RootState } from "store";
import { store } from "store";
import { setMessage, setCurrentUser } from "store/slices/commonSlice";
import { apis } from "api";
import { useWindowSize } from "libs/hook";

interface AppLayoutProps {
    child: React.ReactNode,
    auth?: boolean
}

const { Content } = Layout;

const AppLayout: React.FC<AppLayoutProps> = ({ child, auth }) => {
    const location = useLocation();
    const [width] = useWindowSize();
    const [collapsed, setCollapsed] = React.useState(width < 650);
    const [messageApi, contextHolder] = Message.useMessage();
    const { message, accessToken } = useSelector((state: RootState) => state.common);

    const getCurrentUser = async () => {
        try {
            const response: any = await apis.GetCurrentUser();
            store.dispatch(setCurrentUser(response.user));
        } catch (err: any) {
            store.dispatch(setMessage({
                type: 'error',
                content: err?.response?.data.error || 'Network Error'
            }))
        }
    }

    React.useEffect(() => {
        if (message) {
            messageApi.open({
                type: message.type,
                content: message.content
            })
        }
    }, [message]);

    React.useEffect(() => {
        if (accessToken) {
            getCurrentUser()
        }
    }, [accessToken]);

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <Layout>
            {contextHolder}
            {!auth ?
                <AppHeader
                    setCollapsed={setCollapsed}
                    collapsed={collapsed}
                /> : null}
            <Layout>
                {(!auth && width >= 650) ?
                    <AppSider collapsed={collapsed} /> : null}
                <Content
                    style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        padding: '30px 10px'
                    }}
                >
                    {child}
                </Content>
            </Layout>
            {(!auth && width < 650) ?
                <AppDrawer
                    setCollapsed={setCollapsed}
                    collapsed={collapsed}
                /> : null}
        </Layout>
    )

}

export default AppLayout;