import React from "react";
import { Row, Modal, Button, Col, Typography, Input, Form, Flex } from "antd";
import { setMessage } from "store/slices/commonSlice";
import { apis } from "api";
import { store } from "store";

interface editProfileDialogProps {
    open: boolean,
    setOpen: (value: boolean) => void,
    handleSuccess: (url: string) => void,
    id: string,
    name: string,
    username: string,
    bio?: string
}

type FieldType = {
    username?: string;
    name?: string;
};

const EditProfileDialog: React.FC<editProfileDialogProps> = ({
    open,
    setOpen,
    handleSuccess,
    id,
    name,
    username,
    bio
}) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [profileName, setProfileName] = React.useState<string>(name);
    const [profileUsername, setProfileUsername] = React.useState<string>(username);
    const [profileBio, setProfileBio] = React.useState<string>(bio || "");

    const handleSave = async () => {
        try {
            setIsLoading(true);
            const response: any = await apis.UpdateBasicUserInfo(id, {
                name: profileName,
                username: profileUsername,
                bio: profileBio
            });
            if (response.result) {
                setIsLoading(false);
                store.dispatch(setMessage({
                    type: 'success',
                    content: 'Profile has been updated successfully'
                }));
                handleSuccess(profileUsername);
                setOpen(false);
            }
        } catch (err: any) {
            setIsLoading(false);
            store.dispatch(setMessage({
                type: 'error',
                content: err?.response?.data.error || 'Network Error'
            }));
        }
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Modal
            title="Edit Profile"
            centered
            open={open}
            onCancel={handleClose}
            width={'90%'}
            style={{ maxWidth: 500 }}
            footer={null}
        >
            <Form
                name="basic"
                onFinish={handleSave}
                autoComplete="off"
            >
                <Row style={{ marginTop: 10 }}>
                    <Col span={24}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Name
                        </Typography.Title>
                        <Form.Item<FieldType>
                            name="name"
                            rules={[{ required: true, message: 'Please input your name!' }]}
                            initialValue={name}
                        >
                            <Input
                                style={{ width: '100%' }}
                                placeholder="Name"
                                value={profileName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setProfileName(e.target.value)}
                                maxLength={50}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Username
                        </Typography.Title>
                        <Form.Item<FieldType>
                            name="username"
                            rules={[
                                { required: true, message: 'Please input your username!' },
                                {
                                    validator: async (_, value) => {
                                        if (value) {
                                            try {
                                                const response: any = await apis.CheckUsername(id, value);
                                                if (response.result) {
                                                    return Promise.reject('Username is already taken.');
                                                } else {
                                                    return Promise.resolve();
                                                }
                                            } catch (err: any) {
                                                store.dispatch(setMessage({
                                                    type: 'error',
                                                    content: err?.response?.data.error || 'Network Error'
                                                }));
                                            }
                                        }
                                    },
                                }
                            ]}
                            initialValue={username}
                        >
                            <Input
                                style={{ width: '100%' }}
                                placeholder="Username"
                                value={profileUsername}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setProfileUsername(e.target.value)}
                                maxLength={50}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Bio
                        </Typography.Title>
                        <Input.TextArea
                            style={{ width: '100%', resize: 'none' }}
                            placeholder="Please input bio..."
                            value={profileBio}
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setProfileBio(e.target.value)}
                            maxLength={200}
                            autoSize={{ minRows: 2 }}
                        />
                    </Col>
                    <Col span={24} style={{ marginTop: 10 }}>
                        <Flex justify="flex-end" gap={15}>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Form.Item style={{ margin: 0 }}>
                                <Button type="primary" htmlType="submit" loading={isLoading}>
                                    Save
                                </Button>
                            </Form.Item>
                        </Flex>

                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default EditProfileDialog;