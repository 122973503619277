import React from "react";
import { Flex, Row, Col, Typography, Avatar } from "antd";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "libs/hook";
import { secondsToHMS, numberFormat, pastTimeFormat } from "libs/common";
import DefaultAvatar from "assets/images/default-avatar.jpeg";

interface VideoCardProps {
    thumbnail: string,
    duration: number,
    title: string,
    avatar?: string,
    name: string,
    views: number,
    postedAt: string
}

const VideoCard: React.FC<VideoCardProps> = ({
    avatar,
    thumbnail,
    duration,
    title,
    name,
    views,
    postedAt
}) => {
    const cardRef = React.useRef<any>(null);
    const navigate = useNavigate();
    const [height, setHeight] = React.useState(150);
    const [hover, setHover] = React.useState(false);
    const [width] = useWindowSize();

    const formattedDuration = React.useMemo(() => {
        if (duration) {
            return secondsToHMS(duration)
        } else return '';
    }, [duration]);

    React.useEffect(() => {
        if (cardRef) {
            setHeight(cardRef.current.offsetWidth * 0.65)
        }
    }, [cardRef, width]);

    return (
        <div
            onClick={() => navigate('/videos/videoId')}
            ref={cardRef}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{
                width: '100%',
                borderRadius: 10,
                transform: `scale(${hover ? 1.05 : 1})`,
                transition: '300ms',
                cursor: 'pointer',
                background: 'white',
                boxShadow: '0px 2px 2px rgba(0, 0, 0, .02), 0px 0px 2px rgba(0, 0, 0, .5), 0px 1px 4px rgba(0, 0, 0, .08)'
            }}
        >
            <Row style={{ position: 'relative' }}>
                <img
                    alt="Thumbnail"
                    width={'100%'}
                    height={height}
                    src={thumbnail}
                    style={{ objectFit: 'cover', borderRadius: '10px 10px 0px 0px' }}
                />
                <Typography.Text
                    style={{
                        color: 'black',
                        fontSize: 13,
                        position: 'absolute',
                        background: '#DDD',
                        padding: '0px 3px',
                        fontWeight: 'bold',
                        bottom: 8,
                        right: 8
                    }}>
                    {formattedDuration}
                </Typography.Text>
            </Row>
            <Row style={{ padding: 5 }}>
                <Col span={24}>
                    <Flex gap={10} justify="space-between">
                        <Row style={{ minWidth: 40 }}>
                            <Avatar
                                size={40}
                                src={avatar ? avatar : DefaultAvatar}
                                style={{
                                    boxShadow: '0px 2px 2px rgba(0, 0, 0, .02), 0px 0px 2px rgba(0, 0, 0, .5), 0px 1px 4px rgba(0, 0, 0, .08)'
                                }}
                            />
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Typography.Paragraph
                                    style={{ color: 'black', fontWeight: 'bold', fontSize: 13, margin: 0 }}
                                    ellipsis={{ rows: 2 }}
                                >
                                    {title} my video, my video, my video, my video, my video
                                </Typography.Paragraph>
                            </Col>
                            <Col span={24}>
                                <Typography.Text style={{ color: 'grey', fontWeight: 'bold', fontSize: 12 }}>
                                    {name}
                                </Typography.Text>
                            </Col>
                            <Col span={24}>
                                <Typography.Text style={{ color: 'grey', fontSize: 12 }}>
                                    {numberFormat(views)} Views - {pastTimeFormat(postedAt)} ago
                                </Typography.Text>
                            </Col>
                        </Row>
                    </Flex>
                </Col>
            </Row>
        </div>
    )
}

export default VideoCard;