import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Typography } from "antd";

const Privacy: React.FC = () => {
    return (
        <Row style={{ maxWidth: 1200, marginLeft: 'auto', marginRight: 'auto', padding: '0px 10px' }}>
            <Col span={24}>
                <Typography.Title level={2} style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 30 }}>Privacy Policy</Typography.Title>
            </Col>
            <Col span={24}>
                <Typography.Text style={{ fontSize: 18, fontWeight: 500 }}>Dated: March, 6th 2024</Typography.Text>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginBottom: 10 }}>Introduction</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    This Privacy Policy (policy) explains how KriTikki apps, websites, software, and related services,
                    KriTikki Inc and its affiliates (“KriTikki”, “Service”, “Product”, “Platform” “we”, “our”, or “us”) collect, use
                    and share your information. Regardless of how you use or access our Services—including through
                    mobile devices and apps—this User Privacy Notice is applicable. When you use our Products, we
                    collect some information about you even if you don't have an account. Your privacy is something that
                    we take very seriously. This Privacy Policy describes how we gather, utilize, distribute, and handle
                    user personal data in different ways. For additional disclosures please refer to State and Regional
                    Privacy and Disclosures of your state and <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a>.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginBottom: 10 }}>What Personal information KriTikki collects?</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500, fontStyle: 'italic' }}>
                    We may collect personal information in connection with our Services such as:
                </Typography.Paragraph>
                <ul>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>The information you give us when you sign up for our Products and create a profile, such as
                            your username and credentials, email address(es), and phone number(s), other contact
                            information, password, privacy setting, notification preferences, profile pictures and other
                            information you provided to us.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>Content you create or share including video and photos you upload. Your posts and photo(s)
                            and message(s) you send and receive. End-to-end encrypted messaging in reported incidents.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>What you click on, like or dislike, and comments you make on KriTikki.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>Relevant search results, watched videos, views and engagement with the advertising content
                            information in images, voice and audio.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>Individuals or company you interact with or exchange content on outside websites and
                            applications that make use of our services.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>Your account information when signing up and logging in and off.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>Information from the phone, computer, or tablet you use our Products on, like what kind it is
                            and what version of our app you’re using.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>Information on who your subscribing, friends or following and who your subscribers, friend
                            request and followers are and what you and they do on our Products.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>Identification documents that require age and/or identity verification and for legal and
                            regulatory compliance.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>The details you share, including your gender, age, appearance, and interests, through surveys
                            or your involvement in challenges, research, promotions, marketing campaigns, events, or
                            contests.
                        </Typography.Paragraph>
                    </li>
                </ul>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500, fontStyle: 'italic' }}>
                    The data outlined in this privacy statement may come to us from additional sources, including:
                </Typography.Paragraph>
                <ul>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            Information from third-party platforms, authentication services that are linked to our services.
                            This could include other websites and apps you visit both on and off our Products.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            Data on unique identifiers, operating systems, browser types and settings, device types and
                            settings, mobile network information (phone number and carrier name), and application version
                            numbers.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            Data regarding how your apps, browsers, and devices use our services, such as the date,
                            time, and referrer URL of your request in addition to your IP address, cookies, crash reports,
                            and system activities.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            Information from third party affiliates, we may collect information that you use to register for
                            and your activity with third-party(s), such as when you enroll for marketing incentives.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            Communications with KriTikki including SMS/text messages, phone calls, chats, and emails
                            with us related to our Services, customer support inquiries and outreach, the date and time of
                            communications, method or channel used, call recordings (if applicable), call volume, the
                            outcomes, actions, and/or resolutions from the communications, and other information.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            Information when service on your device contacts our servers — for example, when you install
                            an app from the Application Store or when a service checks for automatic updates.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            Geolocation information when location access is enabled, we may receive and store your
                            precise location information when the website/app is running both the background and or
                            foreground. location information to help make some services and products you use more
                            helpful. These include GPS and other sensor data from your device. Activity on browser
                            services, such as from your searches or places your label such as home, work. Information
                            about things near your device, such as Wi-Fi access points, cell towers, and Bluetooth-enabled
                            devices.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            Purchase details, such as billing and shipping addresses, payment card numbers, and
                            additional third-party payment information (like PayPal) that may be needed for the purpose of
                            payment. We also gather data about your transactions and past purchases made on or
                            through the Platform, as well as the information needed for extended warranty purposes.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            With your consent, your contacts on social media and phone. In the event that you decide to
                            utilize your phone contacts to locate other users, we will access and gather personal data,
                            including names, phone numbers, and email addresses, and compare it to the records of
                            current Platform users. We will gather the names and profiles of your social network contacts
                            in addition to your public profile information if you decide to search for other users through your
                            contacts on social media.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            We may obtain information from third-party services, such as your public profile details (such
                            as nickname), email address, and contact list, if you opt to link your KriTikki account to a thirdparty service or register or log in to the Platform using one of these platforms such as
                            Facebook, Twitter, Instagram, TikTok or Google.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            Certain connected companies within our corporate group may provide us with information
                            about you, including information about your usage of their platform.
                            We might obtain information about you from other sources if your contact information is given
                            to us, if you are featured or mentioned in User Content, direct messages, complaints, appeals,
                            requests, or feedback that is submitted to us.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            Additionally, companies, individuals, and other sources may provide us with information about
                            you. These sources could include publicly accessible records, governmental agencies, trade
                            associations, and nonprofit organizations.
                        </Typography.Paragraph>
                    </li>
                </ul>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500, fontStyle: 'italic' }}>
                    We may receive the information described in this privacy statement from automatic sources, such as:
                </Typography.Paragraph>
                <ul>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            Certain metadata associated with the User Content is automatically uploaded when you
                            generate or upload User Content. In addition to describing other data, metadata gives your
                            User Content information that the viewer may not always be able to see. For instance, the
                            metadata associated with your User Content may specify the format of the content as well as
                            the how, when, where, and by whom it was created, gathered, or altered. It also contains
                            details that other users can use to link the User Content to your user account, including your
                            account name. Metadata also contains any information you wish to include with your User
                            Content, such as any hashtags you use to identify keywords in the video and captions.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            We also Cookies to improve your experience using the Platform, measure the effectiveness of
                            advertisements and other content, and improve the Platform, we, along with our service
                            providers and business partners, use cookies and other similar technologies (e.g., web
                            beacons, flash cookies, etc.) ("Cookies") to automatically collect information, measure, and
                            analyze how you use the Platform, including which pages you view most frequently and how
                            you interact with content. Cookies are also used by us and our partners to advertise the
                            Platform on other websites and platforms. The Platform may offer specific features and
                            functionality only with the help of cookies. Web beacons, sometimes referred to as "pixel tags"
                            or "clear GIFs," are little images or data bits placed in images that can recognize cookies as
                            well as the date and time and page visited, together with related data from your computer or
                            device and a description of the page where the pixel tag is inserted.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            We may link your contact or account information with your activities on and off our Platform
                            across all your devices. Using your email address or other log-in or device information, we
                            might use this data to provide you personalized adverts on our platform based on your likes,
                            preferences, and traits.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            The information practices of our business partners and service providers are not covered by
                            this Privacy Policy, and we are not liable for their privacy policies.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            The data mentioned above may be combined or de-identified. This Privacy Policy does not
                            apply to de-identified or aggregated data.
                        </Typography.Paragraph>
                    </li>
                </ul>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginBottom: 10 }}>How We Use Your Information</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    We use your information, as detailed below, to fulfill and enforce our Terms of Service, to
                    administer, support, and enhance the Platform, and to enable you to access its features. In
                    addition, we might use your information to advertise the Platform, provide you recommendations,
                    and personalize your ad experience.
                </Typography.Paragraph>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500, fontStyle: 'italic' }}>
                    Generally speaking, we use the data we gather to:
                </Typography.Paragraph>
                <ul>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            To respond to inquiries for goods, services, platform functionality, help, and data for internal
                            operations—including troubleshooting, data analysis, testing, statistical analysis, research, and
                            survey purposes—as well as to request your input.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            To alter the content that appears to you on the Platform. For instance, we might provide you
                            services in accordance with the country settings you've selected or display material you have
                            liked or interacted with.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            To distribute marketing materials either directly from us or on behalf of reputable outside
                            parties and our affiliates.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            To carry out product development and enhance our platform.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            To deliver advertising, including tailored advertising, to you on the Platform and to track and
                            analyze the effectiveness of the advertisements we offer to you and others.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            To offer advice and a personalized advertising experience.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            To facilitate the social features of the Platform, such as allowing users to connect with one
                            another (for instance, through our Find Friends feature), recommend accounts to other users,
                            and allow users to share, download, and engage in other interactions with User Content that is
                            posted on the Platform.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            To promote the Platform through our marketing and advertising activities using User Content.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            To comprehend your usage of the Platform, including all of your gadgets.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            To deduce further details about you, such your interests, gender, and age.
                            to assist us in identifying fraud, abuse, and unlawful activities on the Platform.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            To enhance the safety and security of the Platform, we may do things like check User Content,
                            messages, and related information for infractions of our community guidelines, terms of
                            service, or other rules and regulations.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            To confirm that you are aged enough to use the Platform (as required by law), to access
                            specific features, including livestreaming or verified accounts, or when you apply for a
                            business account, or in other situations where verification could be needed.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            To keep in touch with you, including informing you of service changes.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            If allowed by the terms of the promotion, to notify you as the winner of any competitions or
                            promotions you've entered and to send you any relevant prizes.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            To uphold the Community Guidelines, our Terms of Service, and other agreements and
                            guidelines.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            To offer you location-based services, like advertising and other customized information, in
                            accordance with your permissions.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            To develop and hone our technological tools, like the algorithms and machine learning models
                            we use.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            To compile all of the data we obtain or gather about you in order to fulfill any of the
                            aforementioned objectives.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            To enable the purchase, selling, and promotion of goods and services as well as to offer
                            customer assistance.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            For any further uses that are authorized by you or that are known to you at the time your
                            information is collected.
                        </Typography.Paragraph>
                    </li>
                </ul>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginBottom: 10 }}>Service Providers and Business Partners</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    In order to support our business operations and for business purposes, we share the categories of
                    personal information listed above with service providers and business partners. These categories
                    include:
                </Typography.Paragraph>
                <ul>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            Research, database maintenance, payment processing and transaction fulfillment, technology
                            services, delivery, sending communications, advertising and marketing services, analytics,
                            measurement, data storage and hosting, disaster recovery, search engine optimization, and
                            data processing.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            These vendors and business associates could consist of: payment processors and transaction
                            fulfillment providers, including, in particular, the following third-party payment
                            providers/processors: PayPal (<a href="https://www.paypal.com/us/webapps/mpp/ua/privacy-full">https://www.paypal.com/us/webapps/mpp/ua/privacy-full</a>) and
                            Stripe (<a href="https://stripe.com/en-ie/privacy">https://stripe.com/en-ie/privacy</a>), who may receive the information you provide,
                            information from other sources, and automatically collected information but who do not receive
                            your message data.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            Information from other Sources, Automatically Collected Information, and Information you
                            Provide may be received by customer and technical support providers.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            Scholars who might obtain the data you submit, data from external sources, and data that is
                            automatically gathered; nevertheless, they won't see your payment details or message
                            content.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            Vendors of analytics, marketing, and advertising may obtain information from you, from other
                            sources, and through automatically collected data; however, they will not obtain payment
                            details or message data.
                        </Typography.Paragraph>
                    </li>
                </ul>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginBottom: 10 }}>Among Our Business Group</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    Being a worldwide business, the Platform is supported by specific corporate group organizations, to
                    which we grant restricted remote access to the Information We Collect when needed to allow them to
                    carry out specific critical functions.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginBottom: 10 }}>In Relation to a Business Transfer, Sale, or Merger</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    In the unlikely event of bankruptcy, we may disclose all of the information we collect in connection
                    with a significant corporate transaction, such as the sale of an asset, a merger, consolidation, or asset
                    purchase.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginBottom: 10 }}>Due to Legal Considerations</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    In order to comply with subpoenas, court orders, legal process, law enforcement demands, legal
                    claims, or government inquiries, as well as to safeguard and defend the rights, interests, safety, and
                    security of the Platform, our affiliates, users, or the general public, we may disclose any information
                    that we collect. In order to enforce any agreements that apply to the Platform, to pursue or defend
                    legal claims, or to comply with any relevant laws, we may also disclose any information that we
                    collect.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginBottom: 10 }}>With Your Authorization</Typography.Title>
                <ul>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            If you give us permission or direct us to do so, we may use your information for additional
                            reasons.<br /><br />
                            We collaborate with other services (including Facebook, Instagram, Twitter, and Google) to
                            provide you with a smooth registration, login, and sharing of content experience. If you decide
                            to use these features, we might provide these third-party services access to information about
                            you. For instance, in compliance with the third-party services' privacy policies, the services
                            may obtain information about your activities on the Platform and may alert your connections
                            about your use of the Platform. We will give a third-party service access to your account in
                            exchange for us sharing some personal information about you. Based on the authorizations
                            you provide, the third party might.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            You should be aware that any information you share on the Platform may be read, collected, or
                            utilized by other users if you decide to participate in public activities. When providing personal
                            information on the Platform, you should exercise caution. The information that you decide to
                            submit is not our responsibility.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            We share transaction-related data with the third party, as well as their service providers and
                            transaction fulfillment providers, when you make a purchase from a third party on the Platform,
                            including from a merchant offering goods through our shopping features. You give us
                            permission to share your information in this manner by completing the purchase. The
                            information shared may be used by these organizations in line with their privacy rules.
                        </Typography.Paragraph>
                    </li>
                </ul>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginBottom: 10 }}>Your Rights</Typography.Title>
                <ul>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            Requests to access, update, or remove the data we have on file about you can be made at
                            https://www.KriTikki.com/report. If we have reached a decision regarding your request, you
                            have the option to appeal it by following the guidelines provided in the email we sent you
                            informing you of our decision. Additionally, you can use the information, access, correction,
                            deletion, and appeal rights by mailing your request to the physical location listed in the
                            "Contact Us" section below. Via your in-app settings, you may also instantly amend your
                            account details, obtain a copy of your KriTikki data, and ask for the deactivation or deletion of
                            your account.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            You have the right to know what personal data we have on file about you, including the types
                            of sources from which the data was gathered where the personal data is gathered from, the
                            business or commercial reason for gathering or disclosing personal data, the kinds of third
                            parties with whom we share the data, and the particular personal data points we have
                            amassed about you. We don't treat people differently because you exercised your right to
                            privacy.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            Depending on the relevant laws, you might be able to make a request via an authorized agent.
                            Please present proof that you have granted the designated agent power of attorney or that the
                            agent otherwise possesses legitimate written authority to submit requests to exercise rights on
                            your behalf in order to designate an authorized agent to make decisions on your behalf.
                            Subject to appropriate verification and in accordance with applicable law, we shall reply to your
                            request. We will ask you to transmit your request from the email address linked to your
                            account or to provide the information we need to validate your account in order to verify it.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            Although some of the data we gather and use might be considered sensitive personal data, we
                            only use this data to the extent necessary to operate the Platform and in accordance with other
                            legal exemptions. For instance, we might use your driver's license number to confirm your
                            identification or your financial information to deliver you the products or services you want from
                            us.
                        </Typography.Paragraph>
                    </li>
                </ul>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginBottom: 10 }}>Your Choices</Typography.Title>
                <ul>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            By disabling or refusing cookies on your device, you may be able to manage some of the
                            information that we collect. Please refer to the instructions supplied by your browser, as every
                            browser is unique. Please be aware that in order to reject or disable specific Cookie types, you
                            might need to perform extra actions. Furthermore, you might need to disable Cookies
                            differently for each type of browser or device because your option to do so is specific to the
                            particular browser or device that you are using. Some features of the Platform might not work
                            for you if you decide to reject, disable, or remove Cookies. We are unable to give you anything
                            without this information.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            To stop seeing targeted ads based on private information about your online and app usage on
                            unaffiliated platforms, go to "Ads" in your in-app settings.
                            You can use the options at https://www.networkadvertising.org/managing/opt_out.asp and
                            https://www.aboutads.info/choices to manage your preferences for some of the third parties we
                            deal with to serve advertising throughout the Internet.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            There could be settings on your device that control the information we collect. For instance,
                            you can manage through the settings on your Apple and Android smartphones whether we can
                            obtain your mobile advertising identification for advertising purposes.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            Using the "unsubscribe" link or other method specified in marketing or advertising emails, you
                            can choose not to receive further marketing or advertising emails.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            The app does may not yet get exact or approximative GPS data from users in the United
                            States. You can stop your device from sharing GPS data with the Platform at any time if you
                            are still using an older version (last released in August 2020) and you gave us permission to do
                            so. This can be done through the operating system settings on your device.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            If you have created an account, you can use the features and functionalities offered to log into
                            your account and access, review, and change some of the personal information you have
                            given us.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            A few web browsers get "do-not-track" signals from certain browsers. We don't do anything in
                            response to these signals at this time due to variations in how browsers implement and use
                            this capability.
                        </Typography.Paragraph>
                    </li>
                </ul>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginBottom: 10 }}>Data Security and Retention</Typography.Title>
                <ul>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            We take appropriate precautions to guard against loss, theft, misuse, unauthorized access,
                            disclosure, modification, and destruction of information. It is important for you to realize that
                            there is no 100% secure data storage solution or data transmission via the Internet or any
                            other public network. We are not liable for maintaining the security of information gathered by
                            third parties, so please be aware that it might not have the same security measures as
                            information you provide to us.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            In addition, we save information when it's required to fulfill legal and contractual requirements,
                            when we have a good reason to (such when we're expanding and growing the Platform and
                            boosting its stability, safety, and security), and when it comes time to prosecute or defend legal
                            claims.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            Various factors, including the type of information and our intended uses for it, influence the
                            length of the retention periods. For instance, we retain your information for as long as you have
                            an account when we handle it, such as when we use your profile information to allow you
                            access to the Platform. We may instantly delete your profile if you breach our terms of service,
                            community guidelines, or other rules and regulations. However, we may retain additional
                            information about you in order to handle the violation.

                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            Your data may be sent by KriTikki o servers or data centers located outside of the US for
                            processing and/or storing. KriTikki may share your data as mentioned below with organizations
                            that are based outside of the United States.
                        </Typography.Paragraph>
                    </li>
                </ul>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginBottom: 10 }}>Children and Teens </Typography.Title>
                <ul>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            We value children's privacy very much. For children in the US, we offer an alternative
                            experience in which we gather and use a restricted amount of data. See our <Link to="/">Children's
                                Privacy Policy</Link> for details on how we gather, utilize, distribute, and treat children's personal
                            information in various ways.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            In any case, children are not the target audience for the Platform. In the event that we learn
                            that a child's personal information has been gathered on the Platform, we will remove it and
                            close the child's account. Please get in touch with us at https://KriTikki.com/report if you think
                            there is a user who is less than 13 years old.
                        </Typography.Paragraph>
                    </li>
                </ul>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginBottom: 10 }}>Disseminating Information for Direct Marketing Objectives </Typography.Title>
                <ul>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            If you live in California, you may be able to get information about any personal information we
                            may have shared with other companies for their own direct marketing purposes, once each
                            calendar year. Please use our contact form at https://www.KriTikki.com/report to file a request.
                        </Typography.Paragraph>
                    </li>
                </ul>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500, fontStyle: 'italic' }}>
                    Users Under 18 May Remove Content
                </Typography.Paragraph>
                <ul>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            Users under the age of eighteen who reside in California may contact us at
                            https://www.KriTikki.com/report to seek and get the removal of any User Content they have
                            uploaded from the Platform. All requests must include enough information to enable us to find
                            the User Content, as well as a description of the User Content you wish to have delete.
                        </Typography.Paragraph>
                    </li>
                    <li>
                        <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                            Requests for California Removal are not accepted by fax, phone, or mail. If you do not send us
                            enough information, we might not be able to respond. Please be aware that your request does
                            not guarantee that the content will be removed entirely or completely. For instance, another
                            user might repost or republish User Content that you have submitted.
                        </Typography.Paragraph>
                    </li>
                </ul>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginBottom: 10 }}>Updates to Privacy Policies</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    This Privacy Policy may be updated from time to time. We intend to notify you when we amend the
                    Privacy Policy by changing the "Last Updated" date at the top of the revised document, posting the
                    revised document, or sending you any other notice mandated by relevant legal requirements. To stay
                    up to date on our privacy practices, we advise you to read the Privacy Policy each time you use the
                    Platform.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    Get in touch with us<br />
                    For inquiries, suggestions, and complaints about this privacy statement, contact:<br />
                    https://www.KriTikki.com/report
                </Typography.Paragraph>
            </Col>
        </Row>
    )
};

export default Privacy;