import React from "react";
import { Row, Col, Typography, Flex, Button, Divider, Input, Avatar } from "antd";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { pastTimeFormat } from "libs/common";
import { LikeOutlined, LikeFilled, PlusOutlined } from "@ant-design/icons";

import avatarImage from "assets/images/avatar.png";
import image from "assets/images/images4.png";
import video from "assets/videos/video2.mp4";
import Comment from "components/common/Comment";

const VideoDetail: React.FC = () => {
    const { videoId } = useParams();
    const { currentUser } = useSelector((state: RootState) => state.common);
    const [comment, setComment] = React.useState<string>("");
    const [showComment, setShowComment] = React.useState<boolean>(false);

    return (
        <Row style={{ maxWidth: 900, marginLeft: 'auto', marginRight: 'auto' }}>
            <Col span={24}
                style={{
                    position: 'relative',
                    paddingTop: '56.25%'
                }}
            >
                <ReactPlayer
                    url={video}
                    controls
                    playing
                    light={image}
                    width={'100%'}
                    height={'100%'}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0
                    }}
                />
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ margin: '15px 0px 5px 0px' }}>
                    This is video title This is video title This is video title This is video title This is video title
                </Typography.Title>
                <Typography.Paragraph style={{ margin: '0px 0px 5px 0px', fontSize: 16 }}>
                    This is video description. This is video description. This is video description. This is video description. This is video description.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Flex justify="space-between">
                    <Flex gap={10}>
                        <Typography.Text style={{ color: 'grey', fontWeight: 500 }}>123,456 views</Typography.Text>
                        <Typography.Text style={{ color: 'grey', fontWeight: 500 }}>{pastTimeFormat("Tue Feb 25 2024 12:00:00 GMT+0000")} ago</Typography.Text>
                    </Flex>
                    <Flex align="center" justify="center">
                        <Button
                            type="link"
                            size="large"
                            icon={<LikeOutlined />}
                        />
                        <Typography.Title level={5} style={{ margin: 0, fontWeight: 'bold' }}>31K</Typography.Title>
                    </Flex>
                </Flex>
            </Col>
            <Divider style={{ margin: 0, borderWidth: 2 }} />
            <Col span={24} style={{ marginTop: 10, marginBottom: 10 }}>
                <Flex justify="space-between" align="center">
                    <Flex justify="center" gap={10} align="center">
                        <Avatar size={50} src={avatarImage} />
                        <Flex vertical>
                            <Typography.Title level={5} style={{ margin: 0, fontWeight: 'bold' }}>Ng Jennie</Typography.Title>
                            <Typography.Text style={{ color: 'grey' }}>1234 Subscribers</Typography.Text>
                        </Flex>
                    </Flex>
                    <Button type="primary">SUBSCRIBE</Button>
                </Flex>
            </Col>
            <Divider style={{ margin: 0, borderWidth: 2 }} />
            <Col span={24} style={{ marginTop: 10, marginBottom: 10 }}>
                <Flex justify="space-between" align="center" style={{ height: 32 }}>
                    <Typography.Title level={4} style={{ margin: 0 }}>10 Comments</Typography.Title>
                    {showComment ?
                        <div></div> :
                        <Button
                            type='primary'
                            icon={<PlusOutlined />}
                            onClick={() => setShowComment(true)}
                        >
                            COMMENT
                        </Button>
                    }
                </Flex>
                {(currentUser && showComment) ?
                    <Row style={{ marginTop: 10 }}>
                        <Col flex="55px">
                            <Avatar size={45} src={currentUser.avatar} />
                        </Col>
                        <Col flex="auto" style={{ display: 'flex', alignItems: 'center' }}>
                            <Input.TextArea
                                autoSize
                                placeholder="Add a comment..."
                                onChange={(event: any) => setComment(event.target.value)}
                            />
                        </Col>
                        <Col span={24} style={{ marginTop: 5 }}>
                            <Flex justify="flex-end" gap={10}>
                                <Button onClick={() => {
                                    setShowComment(false);
                                    setComment("")
                                }}>CANCEL</Button>
                                <Button type="primary" disabled={comment === ""}>COMMENT</Button>
                            </Flex>
                        </Col>
                    </Row> : null
                }
                <Row style={{ marginTop: 20 }}>
                    <Comment
                        avatar={avatarImage}
                        name="Jennie Ng"
                        commentedAt='Tue Feb 27 2024 01:00:00 GMT+0000'
                        content="Lewandowski once again shows his high level. He is really a level above the rest of the attackers.I'm really happy for Dembele, he is doing his best for the team. His assist to Lewandowski was beautiful.🔥"
                        replies={[
                            { 
                                repliedBy: {        
                                    avatar: avatarImage,
                                    name: 'Test User',
                                    username: 'testuser'
                                },
                                repliedAt: 'Tue Feb 28 2024 01:00:00 GMT+0000',
                                content: `that's awesome!`
                            }
                        ]}
                    />
                     <Comment
                        avatar={avatarImage}
                        name="Jennie Ng"
                        commentedAt='Tue Feb 27 2024 01:00:00 GMT+0000'
                        content="Lewandowski once again shows his high level. He is really a level above the rest of the attackers.I'm really happy for Dembele, he is doing his best for the team. His assist to Lewandowski was beautiful.🔥"
                        replies={[
                            { 
                                repliedBy: {        
                                    avatar: avatarImage,
                                    name: 'Test User',
                                    username: 'testuser'
                                },
                                repliedAt: 'Tue Feb 28 2024 01:00:00 GMT+0000',
                                content: `that's awesome!`
                            }
                        ]}
                    />
                </Row>
            </Col>
        </Row>
    )
};

export default VideoDetail;