import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IProfileUser } from "libs/types";

interface userState {
    profileUser: IProfileUser | null
}

const initialState: userState = {
    profileUser: null
}   

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<IProfileUser>) => {
            state.profileUser = action.payload;
        },
    }
})

export const { setUser } = userSlice.actions

export default userSlice.reducer;