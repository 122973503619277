import React from "react";
import { Row, Modal, Button, Upload, Spin, Col, Select, Typography, Input, Flex, Image } from "antd";
import ReactPlayer from "react-player";
import type { UploadProps } from 'antd';
import { InboxOutlined, UploadOutlined, DeleteFilled } from '@ant-design/icons';
import { setUploadModalShow } from "store/slices/videoSlice";
import { store } from "store";
import { CATEGORY_LIST } from "libs/constants";

interface uploadVideoDialogProps {
    open: boolean,
}

const { Dragger } = Upload;

const UploadVideoDialog: React.FC<uploadVideoDialogProps> = ({ open }) => {
    const [isCaptured, setIsCaptured] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [duration, setDuration] = React.useState(0);
    const [video, setVideo] = React.useState<any>(null);
    const [seekCnt, setSeekCnt] = React.useState(0);
    const [thumbs, setThumbs] = React.useState<Array<any>>([]);
    const playerRef = React.useRef<any>(null);
    const inputRef = React.useRef<any>(null);
    const [category, setCategory] = React.useState("");
    const [uploadedCover, setUploadedCover] = React.useState<any>(null);
    const [cover, setCover] = React.useState<any>(null);
    const [tags, setTags] = React.useState<Array<any>>([]);

    const props: UploadProps = {
        name: 'file',
        multiple: false,
        beforeUpload: (file) => {
            setIsLoading(true);
            const videoFile = Object.assign(file, { preview: URL.createObjectURL(file) });
            window.URL = window.URL || window.webkitURL;
            if (file) {
                const video = document.createElement('video');
                video.preload = "metadata";
                video.onloadedmetadata = evt => {
                    setVideo(videoFile);
                }
                video.src = URL.createObjectURL(videoFile);
            }
            return false;
        },
        accept: "video/*",
        showUploadList: false
    };

    const handleClose = () => {
        store.dispatch(setUploadModalShow(false));
    }

    const handleCategoryChange = (value: string) => {

    }

    const handleSave = () => {

    }

    const handleTagChange = (values: any) => {
        setTags(values);
    }

    const uploadImage = (e: any) => {
        const { files } = e.target
        if (files.length === 0) return
        const imageFile = Object.assign(files[0], { preview: URL.createObjectURL(files[0]) });
        setUploadedCover(imageFile);
    }

    const getCoverURL = () => {
        let canvas = document.createElement("canvas") as HTMLCanvasElement;
        const video: any = document.getElementById("element")?.firstChild;
        if (video) {
            let context = canvas.getContext('2d');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            context?.drawImage(video, 0, 0);
            let url = canvas.toDataURL('image/png');
            return url;
        }
    }

    React.useEffect(() => {
        playerRef.current?.seekTo(0);
    }, [duration]);

    React.useEffect(() => {
        if (seekCnt > 0 && seekCnt <= 3) {
            let thumbnails = [...thumbs];
            let period = duration / 3;
            let url = getCoverURL();
            thumbnails.push(url);
            setThumbs(thumbnails);
            if (seekCnt === 3) {
                setIsCaptured(true);
                setIsLoading(false);
            } else {
                playerRef.current?.seekTo(period * seekCnt);
            }
        }
    }, [seekCnt]);

    return (
        <Modal
            title="Upload Video"
            centered
            open={open}
            onCancel={handleClose}
            width={'90%'}
            style={{ maxWidth: 720 }}
            footer={isCaptured ? [
                <Button key="cancel" onClick={handleClose}>
                    Cancel
                </Button>,
                <Button key="save" type="primary" loading={isLoading} onClick={handleSave}>
                    Save
                </Button>
            ] : []}
        >
            {isCaptured ?
                <Row style={{ marginTop: 20 }}>
                    <Col span={24}
                        style={{
                            position: 'relative',
                            paddingTop: '56.25%'
                        }}
                    >
                        <ReactPlayer
                            url={video?.preview}
                            controls
                            width={'100%'}
                            height={'100%'}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0
                            }}
                        />
                    </Col>
                    <Col span={24} style={{ marginTop: 15 }}>
                        <Typography.Title level={4} style={{ margin: 0 }}>
                            Category
                        </Typography.Title>
                        <Typography.Text>
                            Help users locate your video easier.
                        </Typography.Text>
                        <Select
                            style={{ width: '100%' }}
                            onChange={handleCategoryChange}
                            options={CATEGORY_LIST.map((ca: string) => {
                                return {
                                    value: ca,
                                    label: ca
                                }
                            })}
                            size="large"
                            placeholder="Select a category"
                        />
                    </Col>
                    <Col span={24} style={{ marginTop: 15 }}>
                        <Typography.Title level={4} style={{ margin: 0 }}>
                            Title
                        </Typography.Title>
                        <Typography.Text>
                            Include a title that reflects your video.
                        </Typography.Text>
                        <Input
                            style={{ width: '100%' }}
                            size="large"
                            placeholder="Please input title..."
                            maxLength={100}
                        />
                    </Col>
                    <Col span={24} style={{ marginTop: 15 }}>
                        <Typography.Title level={4} style={{ margin: 0 }}>
                            Description
                        </Typography.Title>
                        <Typography.Text>
                            Describe the overview of the video you want your viewers to know.
                        </Typography.Text>
                        <Input.TextArea
                            style={{ width: '100%' }}
                            size="large"
                            placeholder="Please input description..."
                            maxLength={2000}
                            autoSize={{ minRows: 3 }}
                        />
                    </Col>
                    <Col span={24} style={{ marginTop: 15 }}>
                        <Typography.Title level={4} style={{ margin: 0 }}>
                            Thumbnail
                        </Typography.Title>
                        <Typography.Text>
                            Upload or select a snapshot for your video cover.
                        </Typography.Text>
                        <Flex gap={5} justify="space-between" align="center" wrap="wrap">
                            {uploadedCover ?
                                <div style={{ position: 'relative' }}>
                                    <Image
                                        width={160}
                                        height={100}
                                        style={{
                                            objectFit: 'cover',
                                            border: `2px dotted ${cover === uploadedCover ? 'red' : 'grey'}`,
                                            borderRadius: 10,
                                            cursor: 'pointer'
                                        }}
                                        src={uploadedCover.preview}
                                        onClick={() => setCover(uploadedCover)}
                                        preview={false}
                                    />
                                    <Button
                                        style={{ position: 'absolute', top: 5, right: 5, padding: 0, margin: 0 }}
                                        icon={<DeleteFilled style={{ color: 'red' }} />}
                                        type="text"
                                        onClick={() => setUploadedCover(null)}
                                    />
                                </div>
                                :
                                <div>
                                    <Button
                                        type="dashed"
                                        style={{ width: 160, height: 98 }}
                                        onClick={() => inputRef.current.click()}
                                    >
                                        <UploadOutlined />
                                        Upload
                                    </Button>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        hidden
                                        ref={inputRef}
                                        onChange={uploadImage}
                                    />
                                </div>
                            }
                            {thumbs.map((thumb: any, index: number) => (
                                <Image
                                    key={index}
                                    width={160}
                                    height={100}
                                    style={{
                                        objectFit: 'cover',
                                        border: `2px dotted ${cover === thumb ? 'red' : 'grey'}`,
                                        borderRadius: 10,
                                        cursor: 'pointer'
                                    }}
                                    src={thumb}
                                    onClick={() => setCover(thumb)}
                                    preview={false}
                                />
                            ))}

                        </Flex>
                    </Col>
                    <Col span={24} style={{ marginTop: 15 }}>
                        <Typography.Title level={4} style={{ margin: 0 }}>
                            Tags
                        </Typography.Title>
                        <Typography.Text>
                            Add keywords to your video to help viewers find your content. Insert a comma after each tag.
                        </Typography.Text>
                        <Select
                            size="large"
                            mode="tags"
                            style={{ width: '100%' }}
                            onChange={handleTagChange}
                            tokenSeparators={[',']}
                            options={[]}
                        />
                    </Col>
                </Row> :
                <Dragger {...props}>
                    {isLoading ?
                        <Spin size="large" tip="Processing...">
                            <div style={{ height: 300 }}></div>
                            <ReactPlayer
                                hidden
                                id="element"
                                ref={playerRef}
                                url={video?.preview}
                                onReady={() => { setDuration(playerRef.current?.getDuration()) }}
                                onSeek={() => {
                                    if (seekCnt < 3) setSeekCnt(seekCnt + 1);
                                }}
                            />
                        </Spin> :
                        <div
                            style={{
                                height: 300,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        </div>
                    }
                </Dragger>
            }
        </Modal>
    )
}

export default UploadVideoDialog;