import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ACCESS_TOKEN } from "libs/constants";
import type { ICurrentUser, IMessage } from "libs/types";

interface commonState {
    currentUser: ICurrentUser | null;
    accessToken: string | null;
    message: IMessage | null,
}

const initialState: commonState = {
    currentUser: null,
    accessToken: localStorage.getItem(ACCESS_TOKEN) || null,
    message: null
}

export const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        login: (state, action: PayloadAction<string>) => {
            state.accessToken = action.payload;
            localStorage.setItem(ACCESS_TOKEN, action.payload);
        },
        logout: (state) => {
            state.accessToken = null;
            localStorage.removeItem(ACCESS_TOKEN);
            state.currentUser = null;
        },
        setCurrentUser: (state, action: PayloadAction<ICurrentUser>) => {
            state.currentUser = action.payload;
        },
        setMessage: (state, action: PayloadAction<IMessage>) => {
            state.message = action.payload;
        }
    }
})

export const { login, logout, setCurrentUser, setMessage } = commonSlice.actions

export default commonSlice.reducer;