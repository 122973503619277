import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Layout, Menu } from "antd";
import { HomeOutlined, StarOutlined, HeartOutlined, HistoryOutlined, UploadOutlined } from "@ant-design/icons";
import { setUploadModalShow } from "store/slices/videoSlice";
import { type RootState, store } from "store";
import AppFooter from "layout/Footer";

const { Sider } = Layout;

interface AppSiderProps {
    collapsed: boolean
}

const AppSider: React.FC<AppSiderProps> = ({ collapsed }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { accessToken } = useSelector((state: RootState) => state.common);
    const [menuKey, setMenuKey] = React.useState(["home"]);
    const [showFooter, setShowFooter] = React.useState(true);

    const UploadVideo = () => {
        store.dispatch(setUploadModalShow(true));
    }

    const items = [
        {
            key: 'home',
            icon: <HomeOutlined />,
            label: 'Home',
            onClick: () => navigate("/"),
        },
        {
            key: 'subscriptions',
            icon: <StarOutlined />,
            label: 'Subscriptions',
        },
        {
            key: 'loved',
            icon: <HeartOutlined />,
            label: 'Loved',
        },
        {
            key: 'history',
            icon: <HistoryOutlined />,
            label: 'History',
        },
        {
            key: 'upload_video',
            icon: <UploadOutlined />,
            label: 'Upload video',
            authentication: "success",
            onClick: UploadVideo
        },
    ]

    React.useEffect(() => {

    }, [location]);

    React.useEffect(() => {
        setTimeout(() => {
            setShowFooter(!collapsed);
        }, collapsed ? 50 : 200);
    }, [collapsed]);

    return (
        <Sider
            style={{
                background: 'white',
                position: 'sticky',
                left: 0,
                top: 60,
                bottom: 0,
                height: 'calc(100vh - 60px)',
            }}
            trigger={null}
            collapsible
            collapsed={collapsed}
        >
            <Menu
                mode="inline"
                defaultSelectedKeys={menuKey}
                items={accessToken ? items : items.filter((item: any) => item.authentication === undefined)}
            />
            {showFooter ?
                <div style={{ position: 'absolute', bottom: 10, width: '100%' }}>
                    <AppFooter />
                </div> : null}
        </Sider>
    )
}

export default AppSider;