import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Typography } from "antd";

const Terms: React.FC = () => {
    return (
        <Row style={{ maxWidth: 1200, marginLeft: 'auto', marginRight: 'auto', padding: '0px 10px' }}>
            <Col span={24}>
                <Typography.Title level={2} style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 30 }}>Terms Of Service</Typography.Title>
            </Col>
            <Col span={24}>
                <Typography.Text style={{ fontSize: 18, fontWeight: 500 }}>Dated: March, 6th 2024</Typography.Text>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginBottom: 5 }}>Our Service</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    The Service lets you search, view, and share videos. It also acts as a platform for original content creators and small and major advertisers to connect, educate, and inspire people worldwide. In our <Link to="/">Help Center</Link>, we offer an array of information regarding our products and their usage. You can learn about KriTikki Paid Memberships and Purchases (where applicable), and the KriTikki Partner Program, among other subjects.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>Your Provider of Services</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    KriTikki Inc., a California-based business with its headquarters located in Los Angeles, CA is the
                    organization offering the service (referred to as "KriTikki", "we", "us", or "our"). When we refer to
                    KriTikki's "Affiliates," we mean the various businesses that are currently or will eventually be a part of
                    the KriTikki Inc. corporate group.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>Applicable Terms</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    These terms (collectively, this "Agreement"), the KriTikki Community Guidelines, the Policy, Safety,
                    and Copyright Policies, which may be modified from time to time, govern your use of the Service. If
                    you offer advertising or sponsorships to the Service or include paid promotions in your content, your
                    agreement with us will also include the Advertising on KriTikki Policies. The Agreement does not apply
                    to any other links or references that are included in these terms; they are solely offered for
                    informational purposes. Please ensure that you have thoroughly read and comprehend this
                    Agreement. You are not permitted to use the Service if you do not comprehend the Agreement or if
                    you disagree with any element of it.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>Age Limitations</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    To use the Service, you must be at least 13 years old, however kids of all ages can use KriTikki and
                    the service provided their parents or legal guardians grant permission.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10 }}>Authorization from a parent or guardian</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    If you are under 18 years old, you warrant that you have permission to use the Service from your
                    parent or legal guardian. Please ask them to go over this Agreement with you. By permitting your
                    child to use the Service, you, as the parent or legal guardian of a user under the age of 18, agree to
                    the terms of this Agreement and accept responsibility for your child's actions on the Service.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>Company</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    When using the Service on behalf of a business or organization, you warrant that you are authorized
                    to act on the entity's behalf and that such entity consents to the terms of this agreement.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>Information on the Platform</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    Videos, audio, graphics, photos, text, branding (including trade names, trademarks, service marks, or
                    logos), interactive features, software, metrics, and other materials—whether supplied by you, KriTikki,
                    or a third party—are all included in the content on the service (collectively, "Content").
                    The individual or organization that submits content to the Service is accountable for it. It is not
                    required of KriTikki to host or provide content. You can report any content you see that you think
                    violates this Agreement, the <Link to="/">Community Guidelines</Link>, or the law, among other things.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>Channels and Accounts for KriTikki</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    You don't need a KriTikki account to use certain areas of the service, such browsing and content
                    searches. For some functionality, though, a KriTikki account is required. You might be able to like
                    videos, follow channels, make your own KriTikki channel, and do a lot more by creating an account on
                    KriTikki. To establish an account on KriTikki, you can adhere to following guidelines.
                    You can access more features and functionalities, such the ability to post videos, leave comments,
                    and create playlists (where accessible), by creating a KriTikki channel. Here are some instructions for
                    setting up a KriTikki channel of your own.
                    You should keep your password private in order to safeguard your KriTikki account. It is not advisable
                    to utilize your KriTikki account password on unaffiliated programs.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>Privacy Policy</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    Providing our Service requires collecting and using your information. By using KriTikki you agree with
                    its privacy policy. We gather data that you provide to us when you register for our products and set up
                    a profile, such as your phone number or email address. Also, how you use our products. This covers
                    the links and likes you click on, as well as the posts, images, and messages you submit. We are
                    unable to read messages sent by users using end-to-end encryption unless they are reported to us
                    for inspection. We collect who your subscribing, following or friends, and what do they do with our
                    product. We gather details from the tablet, PC, or phone you use our products on, such as the type
                    and the app version you're using. Details about your activities on and off our products from our
                    partners. This might apply to other websites and apps you browse. Learn more on <Link to="/">privacy policy</Link>.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>Permissions and Restrictions</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    As long as you abide by this Agreement and any relevant laws, you are free to access and use the
                    Service as it is made available to you. Content is available for your private, non-commercial use to
                    watch or listen to. The embeddable KriTikki player allows you to display KriTikki videos as well.
                    The following guidelines govern how you can use the Service. Access, reproduction, download,
                    distribution, transmission, broadcast, display, sell, license, alter, modify, or use any part of the Service
                    or any Content in any other way is prohibited, with the exception of the following scenarios: (a) as
                    specifically permitted by the Service; or (b) with prior written permission from KriTikki and, if applicable,
                    the respective rights holders; Any portion of the Service (or an attempt to do any of these things) may
                    be circumvented, disabled, fraudulently used, or interfered with in some other way, including securityrelated measures or features that (a) prohibit or restrict the copying or other use of Content or (b) limit
                    the use of the Service or Content;
                    use any automated methods (such as robots, botnets, or scrapers) to access the Service, with the
                    exception of (a) public search engines operating in accordance with KriTikki's robots.txt file, or (b) with
                    KriTikki's prior written permission; gather or harvest any data that could be used to identify an
                    individual (such as usernames or faces), unless authorized by that individual or permitted under
                    section (3) above; use the service to distribute unsolicited commercial or promotional content or other
                    unwanted or bulk solicitations; create or support any erroneous assessments of actual user
                    interaction with the service, such as by paying or rewarding users to increase a video's views, likes,
                    or dislikes or to subscribe to a channel, or in any other way manipulate metrics;
                    abuse any procedure related to reporting, flagging, complaints, disputes, or appeals, including by
                    submitting frivolous, vexatious, or unfounded claims;
                    conduct competitions on or via the Service in violation of KriTikki's rules and regulations regarding
                    contests; use the Service to: (a) sell any advertising, sponsorships, or promotions placed on, around,
                    or within the Service or Content, other than those allowed in the Advertising on KriTikki policies (such
                    as compliant product placements); or (b) sell advertising, sponsorships, or promotions on any page of
                    any website or application that solely features content from the service or if the primary source of
                    revenue for such sales is content from the service (selling advertisements on a webpage where the
                    main attraction for visitors is watching KriTikki videos, for example). Use the Service to watch or listen
                    to content for purposes other than private, home viewing (you are not permitted to stream music or
                    show videos in public).
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>Reservation</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    By using the Service, you do not acquire ownership or rights to any part of the Service, including
                    usernames or any other content that is submitted to KriTikki or by other users.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>Develop, Enhance, and Upgrade of the Service</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    KriTikki is a dynamic service that is always evolving. We reserve the right to adjust or change (to all or
                    part of the Service) as part of this ongoing evolution, including changing or adding features and
                    functions, introducing new digital material or services, or ending existing ones. In addition, we might
                    have to modify or stop offering the Service whole or in part to enhance security or performance,
                    adjust to legal requirements, or stop unauthorized use of our systems or illicit activity on them. When
                    downloading software is required by the Service or included, changes may affect all users.
                    Depending on your device's settings, the software may update immediately on your device when a
                    new version or feature becomes available. We intend to provide you adequate notice before making
                    any significant changes that would affect how you use the Service, unless there are urgent
                    circumstances, such stopping abuse, meeting legal obligations, or fixing security or operational
                    problems.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>Uploading Content</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    You may be granted the ability to upload Content to the Service if you hold a KriTikki channel. You
                    may advertise your creative or commercial endeavors using your Content. If you decide to contribute
                    content, you agree to abide by the law and this agreement (including the KriTikki <Link to="/">Community Guidelines</Link>) regarding all content you submit to the service. For instance, unless you have permission
                    from the third party or are otherwise permitted by law, you are not permitted to incorporate any thirdparty intellectual property (such as copyrighted material) in the Content you submit. The Content you
                    provide to the Service is your responsibility legally. To assist in identifying infringement and misuse,
                    such as spam, malware, and unlawful content, we might employ automated methods to examine your
                    Content.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>Rights you Grant</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    The rights to your Content are still with you. Yet, as detailed below, we do require you to allow KriTikki
                    and other Service users certain permissions.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>License to KriTikki</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    A worldwide, non-exclusive, royalty-free, sublicensable, and transferable license to use the Content
                    you provide to the Service is granted to KriTikki. This license may be used in connection with the
                    Service and the business of KriTikki (and its successors and Affiliates), including for the promotion and
                    redistributing of all or part of the Service.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>License to Other Users</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    Additionally, you give all other users of the service permission to access your content through the
                    service and to use it in any way that is permitted by a feature of the service such as video playback or
                    embeds, including reproduction, distribution, creation of derivative works, display, and performance.
                    To be clear, this license gives users no authority or authorization to use your Content outside of the
                    Service.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>Duration of License</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    After you remove or delete your Content from the Service, the licenses that you have given remain in
                    effect for a commercially reasonable amount of time. You acknowledge and accept, however, that
                    server copies of your deleted or removed content may be kept by KriTikki; nonetheless, they will not be
                    seen, shared, or performed.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>Right to Monetize</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    You give KriTikki permission to monetize your Content on the Service, which may involve charging
                    users for access or placing advertisements on or within your Content. You are not entitled to any
                    payments under this Agreement. Any payments you may be due from KriTikki under any other
                    arrangement between you and KriTikki (such as payments under the <Link to="/">KriTikki Partner Program</Link>,
                    or Channel subscriptions,) will be considered royalties. KriTikki will deduct taxes from such payments
                    as required by law.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>Removing Your Content</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    At any point you choose, you can take your Content down from the Service. Before deleting your
                    Content, you can alternatively choose to create a copy of it. If you lose the rights specified by these
                    terms, you must remove your Content.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>Removal of Content</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    We have the right to delete some or all of your Content at our discretion if it (1) violates this
                    Agreement or (2) could be harmful to KriTikki, our users, or third parties. Unless we reasonably believe
                    that doing so would: (a) violate the law, the orders of a legal enforcement authority, or expose KriTikki
                    or our Affiliates to legal liability; (b) jeopardize an investigation or the integrity or operation of the
                    Service; or (c) harm any user, other third party, KriTikki, or our Affiliates. In the event of any of these
                    scenarios, we will notify you and provide an explanation for our action.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>Community Guidelines Strikes</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    In regard to Content that transgresses the KriTikki <Link to="/">Community Guidelines</Link>, KriTikki maintains a system of "strikes." Every strike has different limitations and has the potential to permanently remove you channel from KriTikki. The <Link to="/">Community Guidelines Strikes Basics</Link> page has a detailed explanation of how a strike impacts your channel. You can file an appeal <Link to="/">here</Link> if you think a strike was given in error. You are not permitted to utilize another channel to get around restrictions placed on your channel because of a strike. If this restriction is broken, it is a serious violation of this agreement, and KriTikki has the right to <Link to="/">cancel</Link> your account and deny you access to all or some of the service.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>Copyright Protection</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    Through our <Link to="/">KriTikki Copyright Center</Link>, we offer information to assist copyright holders in managing their intellectual property online. Please notify us if you think that the Service has violated your copyright. We reply to copyright infringement notices in accordance with the procedure outlined in our <Link to="/">KriTikki Copyright Center</Link>, where you can also find details on how to handle copyright strikes. KriTikki's
                    policies allow for the termination of serial infringers' access to the Service under suitable
                    circumstances.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>Account Suspension & Termination</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    You may be allowed to discontinue using the Service whenever you choose. To delete the Service
                    from your KriTikki Account, delete your KriTikki channel and associated data with it. If you (a) materially
                    or repeatedly violate this Agreement; (b) we are required to take action in order to comply with a legal
                    requirement or a court order; or (c) we reasonably believe that there has been conduct that creates
                    (or could create) liability or harm to any user, other third party, KriTikki or our Affiliates, KriTikki reserves
                    the right to suspend or terminate your KriTikki account or your access to all or part of the Service.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>Notice for Termination or Suspension</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    Unless we reasonably believe that doing so would: (a) violate the law or the direction of a legal
                    enforcement authority; (b) compromise an investigation; (c) compromise the integrity, operation, or
                    security of the Service; or (d) harm any user, other third party, KriTikki, or our Affiliates, we intend
                    to inform you of the reason for termination or suspension by KriTikki.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>Effect of Account Suspension or Termination</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    You may continue using some parts of the Service (such as viewing only) without an account in the
                    event that your KriTikki account is cancelled or your access to the Service is restricted. In such case,
                    this Agreement will still be applicable. <Link to="/">Use this form</Link> to file an appeal if you think the suspension or
                    termination was handled incorrectly.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>Downloadable Software</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    If the Service calls for or includes downloadable software, then KriTikki grants you a personal,
                    worldwide, royalty-free, non-assignable, and non-exclusive license to use the software that KriTikki
                    provides you as part of the Service, unless those programs are subject to additional terms that
                    contain a license. This license is only intended to help you utilize and benefit from the services that
                    KriTikki provides, in the ways that are allowed by this Agreement. Unless the law forbids such
                    restrictions or you have obtained written authorization from KriTikki, you are not permitted to copy,
                    alter, distribute, sell, lease, or attempt to extract the source code from the software.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>Open Source</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    We may make accessible to you an open-source license for some software utilized in our service.
                    Please make sure to read any open-source licenses you may be using since they may contain
                    restrictions that specifically supersede some of these conditions.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>Warranty Disclaimer</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    KriTikki provides the service "as is" and makes no specific commitments or warranties about the
                    service, apart from what is expressly stated in this agreement or as required by law. as an illustration,
                    we disclaim all warranties regarding the following: (a) content obtained through the service; (b) the
                    features of the service, including its accuracy, dependability, availability, or suitability for your needs;
                    or (C) the accessibility of any content you submit on the service.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>Limitation of Liability</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    Apart from what may be required by applicable law, KriTikki, its affiliates, officers, directors, employees,
                    and agents will not be responsible for any loss of profits, revenues, business opportunities, goodwill,
                    or anticipated savings; loss or corruption of data; indirect or consequential loss; punitive damages
                    caused by: mistakes, errors, or inaccurate information on the service; personal injury or property
                    damage resulting from your use of the service; Any User's or KriTikki's submitted content, including
                    your use of it; any unauthorized access to or use of the service; any interruption or cessation of the
                    service; any viruses or malicious code transmitted to or through the service by any third party; and/or
                    the removal or unavailability of any content.
                    Regardless of whether the claim is based on a contract, warranty, tort, or another legal theory, this
                    provision is applicable to all claims. The entire liability of KriTikki and its affiliates for any claims arising
                    out of or pertaining to the service is capped at the greater of two amounts: (A) the total revenue that
                    KriTikki has paid you from your use of the service during the twelve months prior to the date on which
                    you notify KriTikki in writing of the claim, or (B) $100 USD.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>Indemnity</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    You agree to defend, indemnify, and hold harmless KriTikki, its Affiliates, officers, directors,
                    employees, and agents from and against any and all claims, damages, obligations, losses, liabilities,
                    costs or debt, and expenses (including attorney's fees) arising from: (i) your use of and access to the
                    Service; (ii) your violation of any term of this Agreement; (iii) your violation of any third party right,
                    including without limitation any copyright, property, or privacy right; or (iv) any claim that your Content
                    caused harm to a third party. To the extent permitted by applicable law, you agree in this regard. This
                    Agreement and your use of the Service will be governed by this defense and indemnity requirement.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>Third-Party Links</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    Links to external websites and online services that are not under KriTikki's ownership or control may
                    be found throughout the Service. Such websites and online services are not under KriTikki's control,
                    and it holds no liability for them. When you enter and or exit the Service, be cautious; We advise you
                    to review the terms and privacy policies of every website and online service you visit, including those
                    run by third parties.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>About this Agreement</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    This Agreement may be modified, among other reasons: (1) to reflect modifications to our Service or
                    our business practices, such as the addition or removal of new products or services; (2) for legal,
                    regulatory, or security purposes; or (3) to stop misuse or harm.
                    Except (1) when we introduce a new product or feature, or (2) in an emergency, such stopping
                    continuing abuse or meeting legal obligations, we intend to give you adequate notice of any major
                    changes to this Agreement. You should discontinue using the Service and remove any Content you
                    contributed if you disagree with the revised conditions.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>Continuation of this Agreement</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    The following provisions of this Agreement will still be applicable to you in the event that your use of
                    the Service terminates: "Other Legal Terms," "About This Agreement," and the licenses you have
                    granted will remain in effect for the duration specified under "Duration of License."
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>Severance</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    Any other terms of this Agreement will remain unaffected if it turns out that a specific clause is not
                    enforceable for any reason.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>No Waiver</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    In the event that you violate this Agreement and we do not act immediately, it does not indicate that
                    we are waiving any of our rights, including the ability to act in the future.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>Interpretation</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    "Include" or "including" in these phrases refers to "including but not limited to," and the examples we
                    provide are meant to be illustrative only.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>Governing Law</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    All disputes arising out of or connected to these terms or the Service shall be resolved exclusively in
                    the federal or state courts located in Los Angeles County, California, USA, and shall be governed by
                    California law, excluding California's conflict of laws regulations. KriTikki and you agree to personal
                    jurisdiction in the respective courts.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={4} style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>Limitation on Legal Action</Typography.Title>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    It is agreed upon by you and KriTikki that any legal action arising from or pertaining to the services shall
                    be brought within a year of the cause of action's occurrence. such a cause of action is permanently
                    barred elsewise.
                </Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Paragraph style={{ fontSize: 18, fontWeight: 500 }}>
                    Effective as of March 6th 2024.
                </Typography.Paragraph>
            </Col>
        </Row>
    )
};

export default Terms;