import { combineReducers, configureStore } from '@reduxjs/toolkit';

import commonReducer from "./slices/commonSlice";
import userReducer from "./slices/userSlice";
import videoReducer from "./slices/videoSlice";

export const rootReducer = combineReducers({
    common: commonReducer,
    user: userReducer,
    video: videoReducer
})

export const store = configureStore({
    reducer: rootReducer,
})

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;