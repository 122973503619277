import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper/modules';
import { Flex, Row, Col, Typography, Divider, Button } from "antd";
import VideoCard from "components/common/VideoCard";
import { CATEGORY_LIST } from "libs/constants";
import image from "assets/images/images4.png";
import avatarImage from "assets/images/avatar.png";
import { useWindowSize } from "libs/hook";

import 'swiper/css';
import 'swiper/css/free-mode';

const Home: React.FC = () => {
    const [category, setCategory] = React.useState('All');
    const [width] = useWindowSize();

    return (
        <Row style={{ maxWidth: 1200, marginLeft: 'auto', marginRight: 'auto' }}>
            <Col span={24}>
                <Swiper
                    slidesPerView="auto"
                    spaceBetween={5}
                    freeMode={true}
                    modules={[FreeMode]}
                    style={{
                        width: width >= 650 ? '100%' : 'calc(100vw - 37px)',
                        maxWidth: 1200
                    }}
                >
                    <SwiperSlide style={{ width: 'fit-content' }}>
                        <Button
                            shape="round"
                            size={width >= 650 ? "middle" : "small"}
                            type={category === 'All' ? "primary" : "default"}
                            onClick={() => setCategory('All')}
                        >
                            All
                        </Button>
                    </SwiperSlide>
                    {CATEGORY_LIST.map((ca: string, index: number) => (
                        <SwiperSlide key={index} style={{ width: 'fit-content' }}>
                            <Button
                                shape="round"
                                size={width >= 650 ? "middle" : "small"}
                                type={ca === category ? "primary" : "default"}
                                onClick={() => setCategory(ca)}
                            >
                                {ca}
                            </Button>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Col>
            <Col span={24}>
                <Flex vertical justify="center" align="center">
                    <Typography.Title level={4} style={{ maxWidth: 700 }}>
                        Discover the ultimate destination for insightful video-based reviews on a wide range of products.
                        Join our community and unlock a world of informed decisions!
                    </Typography.Title>
                    <Row>
                        <Typography.Title level={4} style={{ margin: 0, fontWeight: 'bold' }}>
                            Trending
                        </Typography.Title>
                        <Divider style={{ margin: 0, borderWidth: 3, marginBottom: 10 }} />
                        <Row>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, i) => (
                                <Col lg={6} md={8} sm={12} xs={24} style={{ padding: '5px' }} key={i}>
                                    <VideoCard
                                        thumbnail={image}
                                        duration={200}
                                        title="My video"
                                        avatar={avatarImage}
                                        name="mimiknows"
                                        views={34234}
                                        postedAt={'Tue Feb 27 2024 12:00:00 GMT+0000'}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </Row>
                </Flex>
            </Col>
        </Row>
    )
}

export default Home;