import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";
import { Layout, Row, Col, Flex, Button, Input, Avatar, Dropdown } from "antd";
import type { MenuProps } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined, LogoutOutlined, UploadOutlined, BellOutlined, SearchOutlined } from '@ant-design/icons';
import UploadVideoDialog from "components/dialog/UploadVideoDialog";
import { useWindowSize } from "libs/hook";
import { logout } from "store/slices/commonSlice";
import { setUploadModalShow } from "store/slices/videoSlice";
import { store, type RootState } from "store";
import Logo from "assets/images/logo.png";
import MobileLogo from "assets/images/logo-sm.png";
import DefaultAvatar from "assets/images/default-avatar.jpeg";

const { Header } = Layout;

interface AppHeaderProps {
    setCollapsed: (value: boolean) => void,
    collapsed: boolean
}

const AppHeader: React.FC<AppHeaderProps> = ({ setCollapsed, collapsed }) => {
    const navigate = useNavigate();
    const [width] = useWindowSize();
    const { uploadDlg } = useSelector((state: RootState) => state.video);
    const { currentUser } = useSelector((state: RootState) => state.common);

    const Logout = () => {
        store.dispatch(logout());
        navigate("/");
    }

    const Profile = () => {
        if (currentUser) {
            navigate(`/${currentUser.username}`);
        }
    }

    const UploadVideo = () => {
        store.dispatch(setUploadModalShow(true));
    }

    const items: MenuProps['items'] = [
        {
            label: 'Profile',
            key: 'profile',
            icon: <UserOutlined />,
            onClick: Profile
        },
        {
            label: 'Upload video',
            key: 'upload_video',
            icon: <UploadOutlined />,
            onClick: UploadVideo
        },
        { type: 'divider' },
        {
            label: 'Sign out',
            key: 'sign_out',
            icon: <LogoutOutlined />,
            onClick: Logout
        },
    ];

    React.useEffect(() => {
        if (currentUser) {
            const optionss = {
                transports: ['websocket'],
                query: { email: currentUser.email }
            }
            const socket = process.env.NODE_ENV === 'development' ?
                io(process.env.REACT_APP_SERVER_URL as string, optionss) : io(optionss);

            return () => {
                socket.disconnect();
            };
        }
    }, [currentUser]);

    return (
        <Header
            style={{
                position: 'sticky',
                top: 0,
                left: 0,
                zIndex: 10,
                padding: `10px ${width > 650 ? 50 : 20}px 10px ${width > 650 ? 50 : 40}px`,
                height: 'fit-content',
                background: 'white',
            }}
        >
            <Flex
                style={{
                    position: 'absolute',
                    left: 10,
                    height: 'calc(100% - 20px)'
                }}
                justify="center"
                align="center"
            >
                <Button
                    type="text"
                    icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    onClick={() => setCollapsed(!collapsed)}
                />
            </Flex>
            <Row>
                <Col span={7} style={{ display: 'flex', alignItems: 'center', paddingLeft: 5 }}>
                    <img
                        src={width >= 650 ? Logo : MobileLogo}
                        style={{ maxWidth: width >= 650 ? 150 : 35, width: 'calc(100% - 20px)', cursor: 'pointer' }}
                        onClick={() => navigate('/')}
                        alt="Logo"
                    />
                </Col>
                <Col span={11} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {width > 650 ?
                        <Input.Search
                            placeholder="Search..."
                            size="large"
                            enterButton
                            style={{ maxWidth: '700px' }}
                        />
                        : null}
                </Col>
                <Col span={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    {currentUser ?
                        <Flex align="center" gap={3}>
                            {width < 650 ?
                                <Button type="text" size="large" icon={<SearchOutlined />} />
                                : null}
                            <Button icon={<BellOutlined />} type="text" size="large" />
                            <Dropdown menu={{ items }}>
                                <Avatar
                                    style={{
                                        cursor: 'pointer',
                                        boxShadow: '0px 2px 2px rgba(0, 0, 0, .02), 0px 0px 2px rgba(0, 0, 0, .5), 0px 1px 4px rgba(0, 0, 0, .08)'
                                    }}
                                    size={width > 650 ? "large" : "default"}
                                    src={currentUser.avatar ? currentUser.avatar : DefaultAvatar}
                                    icon={currentUser.avatar ? undefined : <UserOutlined />}
                                />
                            </Dropdown>
                        </Flex>
                        :
                        <Button
                            onClick={() => navigate('/login')}
                            size={width >= 735 ? "middle" : "small"}
                        >
                            Sign in
                        </Button>
                    }
                </Col>
            </Row>
            {
                uploadDlg ?
                    <UploadVideoDialog
                        open={uploadDlg}
                    /> : null
            }
        </Header >
    )
}

export default AppHeader;