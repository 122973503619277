import React from "react";
import { Row, Col, Avatar, Typography, Flex, Button, Input } from "antd";
import { LikeOutlined, LikeFilled, CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { pastTimeFormat } from "libs/common";

type IUser = {
    name: string,
    username: string,
    avatar: string
}

type IReply = {
    repliedBy: IUser,
    content: string,
    repliedAt: string
}

interface commentProps {
    avatar: string,
    name: string,
    commentedAt: string,
    content: string,
    replies: Array<IReply>
}

const Comment: React.FC<commentProps> = ({ avatar, name, commentedAt, content, replies }) => {
    const [showReply, setShowReply] = React.useState(false);
    const [showReplies, setShowReplies] = React.useState(false);
    const [reply, setReply] = React.useState("");

    return (
        <Row
            style={{
                margin: '5px 0px',
                padding: 10,
                borderRadius: 10,
                background: 'white',
                boxShadow: '0px 2px 2px rgba(0, 0, 0, .02), 0px 0px 2px rgba(0, 0, 0, .5), 0px 1px 4px rgba(0, 0, 0, .08)'
            }}
        >
            <Col style={{ marginRight: 10, width: 45 }}>
                <Avatar size={40} src={avatar} />
            </Col>
            <Col style={{ width: 'calc(100% - 55px)' }}>
                <Row>
                    <Col span={24}>
                        <Flex gap={8} align="center">
                            <Typography.Title level={5} style={{ margin: 0, fontWeight: 'bold' }}>{name}</Typography.Title>
                            <Typography.Text style={{ color: 'grey' }}>{pastTimeFormat(commentedAt)} ago</Typography.Text>
                        </Flex>
                    </Col>
                    <Col span={24} style={{ marginTop: 5 }}>
                        <Typography.Paragraph style={{ fontSize: 15 }}>{content}</Typography.Paragraph>
                    </Col>
                    <Col span={24}>
                        <Flex align="center">
                            <Button
                                type="link"
                                icon={<LikeOutlined />}
                            />
                            <Typography.Text>312</Typography.Text>
                            <Button
                                type="link"
                                style={{ marginLeft: 10 }}
                                onClick={() => {
                                    if(showReply) setReply("");
                                    setShowReply(!showReply);
                                }}
                            >REPLY</Button>
                        </Flex>
                    </Col>
                    {showReply ?
                        <Col span={24} style={{ marginTop: 5 }}>
                            <Flex justify="space-between" align="center" gap={15}>
                                <Input.TextArea
                                    autoSize
                                    placeholder="Add a reply..."
                                    onChange={(event: any) => setReply(event.target.value)}
                                />
                                <Button
                                    type="primary"
                                    disabled={reply === ""}
                                >REPLY</Button>
                            </Flex>
                        </Col>
                        : null}
                    <Col span={24}>
                        <Button
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                            type="link"
                            icon={showReplies ? <CaretUpOutlined /> : <CaretDownOutlined />}
                            onClick={() => setShowReplies(!showReplies)}
                        >{!showReplies ? 'Show' : 'Hide'} {replies.length} replies</Button>
                    </Col>
                    {showReplies ?
                        <div style={{ marginTop: 5 }}>
                            {replies.map((re: IReply, i: number) => (
                                <Row key={i}>
                                    <Col style={{ marginRight: 10, width: 40 }}>
                                        <Avatar size={35} src={avatar} />
                                    </Col>
                                    <Col style={{ width: 'calc(100% - 50px)' }}>
                                        <Row>
                                            <Col span={24}>
                                                <Flex gap={8} align="center">
                                                    <Typography.Title level={5} style={{ margin: 0, fontWeight: 'bold' }}>{name}</Typography.Title>
                                                    <Typography.Text style={{ color: 'grey' }}>{pastTimeFormat(re.repliedAt)} ago</Typography.Text>
                                                </Flex>
                                            </Col>
                                            <Col span={24}>
                                                <Typography.Paragraph style={{ fontSize: 15 }}>{re.content}</Typography.Paragraph>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            ))}
                        </div>
                        : null}
                </Row>
            </Col>
        </Row>
    )
}

export default Comment;