import axios, { AxiosHeaders } from "axios";
import { ACCESS_TOKEN } from "libs/constants";
import {
    ILoginRequest,
    IRegisterRequest,
    IGoogleAuthRequest,
    IBasicUserRequest
} from "libs/types";

const API = axios.create({
    baseURL: `${process.env.REACT_APP_SERVER_URL}/api`
});

API.interceptors.request.use((config) => {
    try {
        const token = localStorage.getItem(ACCESS_TOKEN);
        if (token) {
            const mHeaders = AxiosHeaders.from({
                Authorization: `Bearer ${token}`,
            })

            if (mHeaders) {
                config.headers = mHeaders
            }
        }
    } catch (error) { }

    return config
})

API.interceptors.response.use(
    (response) => {
        return response.data;
    },
    async (error) => {
        try {
            if (error.response.status === 401) {
                localStorage.removeItem(ACCESS_TOKEN);
                window.location.href = window.location.pathname;
            } else {
                return Promise.reject(error);
            }
        } catch (e) {
            console.log(error);
        }
    }
);

const Login = (data: ILoginRequest) => API.post('/auth/login', data);
const Register = (data: IRegisterRequest) => API.post('/auth/register', data);
const GetCurrentUser = () => API.get('/auth/current_user');
const GoogleAuth = (data: IGoogleAuthRequest) => API.post('/auth/google_auth', data);

const GetProfileUserByUsername = (username: string) => API.post('/user/profile_user', { username: username });
const UpdateBasicUserInfo = (id: string, data: IBasicUserRequest) => API.put(`/user/${id}/basic_user`, data);
const CheckUsername = (id: string, username: string) => API.post(`/user/${id}/check_username`, { username: username });

export const apis = {
    Login,
    Register,
    GetCurrentUser,
    GoogleAuth,
    GetProfileUserByUsername,
    UpdateBasicUserInfo,
    CheckUsername
};