import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Drawer, Button } from "antd";
import { HomeOutlined, StarOutlined, HeartOutlined, HistoryOutlined, UploadOutlined } from "@ant-design/icons";
import { setUploadModalShow } from "store/slices/videoSlice";
import { type RootState, store } from "store";
import AppFooter from "layout/Footer";

interface AppDrawerProps {
    collapsed: boolean,
    setCollapsed: (value: boolean) => void
}

const AppDrawer: React.FC<AppDrawerProps> = ({ collapsed, setCollapsed }) => {
    const navigate = useNavigate();
    const { accessToken } = useSelector((state: RootState) => state.common);

    const Home = () => {
        navigate('/');
        setCollapsed(!collapsed);
    }

    const UploadVideo = () => {
        store.dispatch(setUploadModalShow(true));
        setCollapsed(!collapsed);
    }

    return (
        <Drawer
            placement={'left'}
            closable={false}
            onClose={() => setCollapsed(!collapsed)}
            open={!collapsed}
            key={'left'}
            width={250}
            style={{ position: 'relative' }}
        >
            <Button
                type="link"
                icon={<HomeOutlined />}
                onClick={Home}
                style={{ fontSize: 20, height: 'fit-content', textAlign: 'left', width: 202 }}
            >Home</Button>
            <Button
                type="link"
                icon={<StarOutlined />}
                style={{ fontSize: 20, height: 'fit-content', textAlign: 'left', width: 202 }}
            >Subscriptions</Button>
            <Button
                type="link"
                icon={<HeartOutlined />}
                style={{ fontSize: 20, height: 'fit-content', textAlign: 'left', width: 202 }}
            >Loved</Button>
            <Button
                type="link"
                icon={<HistoryOutlined />}
                style={{ fontSize: 20, height: 'fit-content', textAlign: 'left', width: 202 }}
            >History</Button>
            {accessToken ?
                <div>
                    <Button
                        type="link"
                        icon={<UploadOutlined />}
                        style={{ fontSize: 20, height: 'fit-content', textAlign: 'left', width: 202 }}
                        onClick={UploadVideo}
                    >Upload video</Button>
                </div> : null}
            <div style={{ position: 'absolute', bottom: 10, width: 202 }}>
                <AppFooter />
            </div>
        </Drawer>
    )
}

export default AppDrawer;